
<template>
  <div class="teachers">
    <div class="title">
      <span class="s">教师列表</span>
    </div>
    <div class="search-cell">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="登录名：">
          <el-input
            v-model="formInline.username"
            size="mini"
            placeholder="登录名"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属学校：">
          <el-select
            size="mini"
            v-model="formInline.school_id"
            placeholder="全部"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in schoolList"
              :label="item.school_name"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            size="mini"
            type="primary"
            @click="
              page = 1;
              getTeacherList();
            "
            >查询</el-button
          >

          <el-button size="mini" plain @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tab">
      <el-table
        size="small "
        v-loading="loading"
        border
        :data="tableData.list"
        style="width: 100%; text-algin: center"
        :header-cell-style="{ background: '#F2F2F2', 'border-color': '#000' }"
        :row-style="{ 'border-color': '#000' }"
        :cell-style="{ 'border-color': '#000' }"
        :header-row-style="{ 'border-color': '#000' }"
      >
        <el-table-column
          width="80"
          show-overflow-tooltip
          align="center"
          prop="id"
          label="序号"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="username"
          label="登录名"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="class"
          label="管理班级"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="login_times"
          label="登录次数"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="last_login_time"
          label="最后登录时间"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.last_login_time != 0">
              <div>{{ scope.row.last_login_time | formatTimeS("all") }}</div>
              <div style="margin-top: -6px">
                IP：{{ scope.row.last_login_ip }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="school_name"
          label="所属学校"
        >
        </el-table-column>
      </el-table>
      <el-pagination
        small
        style="margin-top: 20px"
        background
        layout="prev, pager, next"
        :total="tableData.total"
        :page-size="page_size"
        :current-page="page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { teacherList, getSchoolList } from "@/api/admin.js";
export default {
  data() {
    return {
      formInline: {
        username: "",
        school_id: "",
      },
      page: 1,
      page_size: 10,
      tableData: {
        list: [],
      },
      schoolList: [],
      loading: true,
    };
  },
  created() {
    this.getTeacherList();
    this.getCampusList();
  },
  methods: {
    getCampusList() {
      getSchoolList().then((res) => {
        if (res.code == 1) {
          this.schoolList = res.data;
        }
      });
    },
    getTeacherList() {
      this.loading = true;
      teacherList({
        ...this.formInline,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.tableData = res.data;
        }
      });
    },
    pageChange(index) {
      this.page = index;
      this.getTeacherList();
    },
    // 重置
    handleReset() {
      this.formInline = {
        username: "",
        school_id: "",
      };
      this.page = 1;
      this.getTeacherList();
    },
  },
};
</script>
<style lang="less" scoped>
.teachers {
  background: #fff;
  padding: 20px;
  .title {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    .s {
      font-size: 14px;
      color: #ee4d2d;
      border-bottom: 2px solid #ee4d2d;
      padding: 0 10px;
      padding-bottom: 10px;
    }
  }
  .search-cell {
    padding: 10px 0;
  }
}
</style>